<template>
  <div>
    <div style="background-color: #f9f9f9;padding: 25px">
      <div style="display: flex;">
        <el-upload
            class="avatar-uploader"
            :action="uploadApi"
            :headers="headers"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload">
          <img v-if="imageUrl" :src="imageUrl" class="avatar" alt="">
          <el-image v-else style="width: 64px;height: 64px" :src="avatar"></el-image>
          <div class="edit">修改</div>
        </el-upload>
        <div style="margin-left: 20px;padding: 2px 0;flex-direction: column;justify-content: space-between;display: flex;align-items: flex-start;">
          <div class="title-name">{{ isPersonal ? user.name : user.userCompanyVo && user.userCompanyVo.name }}</div>
          <div class="identity" :class="{gray: !isVerified}">
            <img src="../../assets/examine/authen2.png" alt="">
            {{
              isVerified
                  ? (isPersonal ? (user.userTreaty || '合作音乐人') : user.userCompanyVo ? user.userCompanyVo.companyType === 1 ? '合约机构':'认证机构' :'')
                  : '未认证'
            }}
          </div>
        </div>
      </div>
    </div>

    <div v-if="!isVerified" style="text-align: center">
      <img src="@/assets/no-data.png" style="width: 384px;height: 359px;margin-top: 110px" alt=""/><br>
      <span style="color: #999999;font-size: 14px;position: relative;top: -70px">暂无认证信息</span><br>
      <el-button type="primary" @click="$router.push('/verify')" style="border-radius: 3px;position: relative;top: -50px">认证音乐人</el-button>
    </div>
    <template v-else>
      <div v-if="isPersonal">
        <div class="header-title">音乐人认证信息</div>
        <div class="description">
          <div class="label">角色</div>
          <div class="role-tag" v-for="item in roles" :key="item">{{ item }}</div>
        </div>
        <div class="description">
          <div class="label">代表作</div>{{ user.songRepresentative }}
        </div>
        <div class="description">
          <div class="label">主页</div>{{ user.homePagePlatform }}
        </div>
      </div>

      <div v-else>
        <div class="header-title">机构基本信息</div>
        <div class="description">
          <div class="label">公司厂牌名</div>{{ user?.userCompanyVo?.brandName }}
        </div>
        <div class="description">
          <div class="label">公司简介</div>{{ user?.userCompanyVo?.intro }}
        </div>
      </div>

      <div class="divider"></div>

      <div v-if="isPersonal">
        <div class="header-title">机构认证信息</div>
        <div class="description">
          <div class="label">真实姓名</div>{{ user.realName }}
        </div>
        <div class="description">
          <div class="label">证件类型</div>{{ cardType }}
        </div>
        <div class="description">
          <div class="label">证件号码</div>{{ user.cardNo }}
        </div>
        <div class="description">
          <div class="label">国家与地区</div>{{ user.nationality }}
        </div>
      </div>

      <div v-else>
        <div class="header-title">机构基本信息</div>
        <div class="description">
          <div class="label">公司全称</div>{{ user?.userCompanyVo?.name }}
        </div>
        <div class="description">
          <div class="label">统一社会信用代码</div>{{ user?.userCompanyVo?.creditCode }}
        </div>
        <div class="description">
          <div class="label">法人姓名</div>{{ user?.userCompanyVo?.legalPersonName }}
        </div>
        <div class="description">
          <div class="label">法人证件号</div>{{ user?.userCompanyVo?.legalPersonCardno }}
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import defaultAvatar from '@/assets/artist-logo.png'
import store from "@/store"

export default {
  name: "Info",

  data() {
    return {
      imageUrl: '',
      defaultAvatar,

      headers: {
        'X-EFRESH-SECRET': store.state.secret,
        'X-EFRESH-SESSION': store.state.session
      },
    };
  },

  methods: {
    handleAvatarSuccess(res, file) {
      if(res.code === 0){
        this.imageUrl = URL.createObjectURL(file.raw);
      } else {
        this.$message.error(res.msg || '上传头像失败');
      }
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isLt2M;
    }
  },

  computed: {
    uploadApi(){
      return process.env.VUE_APP_BASE_API + '/api/user/uploadAvatar?type=' + (this.isPersonal ? 1 : 0)
    },
    cardType(){
      switch (this.user.cardType) {
        case 0: return '身份证'
        case 1: return '护照'
        default: return ''
      }
    },
    avatar(){
      return (this.isPersonal ? this.user.avatar : this.user?.userCompanyVo?.logo) || defaultAvatar
    },
    roles(){
      return this.user?.roles ? this.user.roles.split(',') : []
    },
    /**
     * 是否是在个人信息的个人信息页
     * @returns {boolean}
     */
    isPersonal(){
      return this.$route.meta.personal === true
    },
    /**
     * 音乐人身份已认证
     * @returns {boolean}
     */
    isVerified() {
      return this.user.verifiedStatus === 3
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .avatar-uploader .el-upload {
  display: flex;
  border-radius: 32px;
  width: 64px;
  height: 64px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  .edit{
    width: 64px;
    height: 22px;
    background-color: #00000066;
    position: absolute;
    bottom: 0;
    color: #ffffff;
    font-size: 12px;
    font-family: PingFang SC, PingFang SC-Regular,system-ui;
  }
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar {
  width: 64px;
  height: 64px;
  display: block;
}
.title-name{
  color: #333333;
  font-size: 16px;
  font-family: PingFang SC, PingFang SC-Regular,system-ui;
}
.identity {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  text-align: LEFT;
  color: #ff004d;
  letter-spacing: 0;
  padding: 0 8px;
  background: rgba(255, 0, 77, 0.05);
  height: 25px;
  width: 101px;
  line-height: 19px;
  box-sizing: border-box;
  border-radius: 4px;
  img {
    width: 14px;
    height: 14px;
    vertical-align: middle;
    margin-right: 6px;
  }
}

.header-title{
  color: #333333;
  font-size: 16px;
  font-family: PingFang SC, PingFang SC-Medium,system-ui;
  font-weight: 500;
  margin-top: 30px;
  margin-bottom: 30px;
}
.description{
  margin-top: 30px;
  margin-bottom: 30px;
  color: #333333;
  font-size: 14px;
  font-family: PingFang SC, PingFang SC-Regular,system-ui;
  .label{
    width: 136px;
    display: inline-block;
  }
}
.role-tag{
  display: inline-block;
  background: #ff004d26;
  border-radius: 3px;
  font-size: 14px;
  font-family: PingFang SC, PingFang SC-Regular,system-ui;
  color: #ff004d;
  line-height: 22px;
  margin-right: 12px;
  padding: 0 6px;
}
.divider{
  border-bottom: solid 1px #dddddd80;
}
.gray{
  filter: grayscale(100%);
  img{
    opacity: .3;
  }
  color: #AAAAAA;
}
</style>
